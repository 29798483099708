import React from "react";
import { Link } from "react-router-dom";
import { SignInSchema } from "../constants/ValidationSchema";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  toast.configure();
  let navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;

  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/login`;

  const handleLogin = (values, resetForm) => {
    const dataToSend = {
      email: values.email,

      password: values.password,
    };

    // send request
    fetch(url, {
      signal: signal,
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        resetForm();
        localStorage.setItem("temp_token", res.token);
        navigate("/dashboard");
        window.location.reload(false);
        return toast.info(res.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    //after getting token value, store it to local storage
    // localStorage.setItem("temp_token", "jwt");
  };

  return (
    // <div className="h-100">
    //   <div className="authincation h-100">
    //     <div className="container h-100">
    //       <div className="row justify-content-center h-100 align-items-center">
    //         <div className="col-md-6">
    //           <div className="authincation-content">
    //             <div className="row no-gutters">
    //               <div className="col-xl-12">
    //                 <div className="auth-form">
    //                   <div className="text-center mb-3">
    //                     <a href="index.html">
    //                       <img src="Simple forms/images/logo.png" alt="" />
    //                     </a>
    //                   </div>
    //                   <h4 className="text-center mb-4 text-white">
    //                     Sign in your account
    //                   </h4>

    //                   {/* <form action="index.html">
    //                     <div className="form-group">
    //                       <label className="mb-1 text-white">
    //                         <strong>Email</strong>
    //                       </label>
    //                       <input
    //                         type="email"
    //                         className="form-control"
    //                         defaultValue="hello@example.com"
    //                       />
    //                     </div>
    //                     <div className="form-group">
    //                       <label className="mb-1 text-white">
    //                         <strong>Password</strong>
    //                       </label>
    //                       <input
    //                         type="password"
    //                         className="form-control"
    //                         defaultValue="Password"
    //                       />
    //                     </div>
    //                     <div className="form-row d-flex justify-content-between mt-4 mb-2">
    //                       <div className="form-group">
    //                         <div className="custom-control custom-checkbox ml-1 text-white">
    //                           <input
    //                             type="checkbox"
    //                             className="custom-control-input"
    //                             id="basic_checkbox_1"
    //                           />
    //                           <label
    //                             className="custom-control-label"
    //                             htmlFor="basic_checkbox_1"
    //                           >
    //                             Remember my preference
    //                           </label>
    //                         </div>
    //                       </div>
    //                       <div className="form-group">
    //                         <Link to="/forgot-password" className="text-white">
    //                           {" "}
    //                           Forgot Password?
    //                         </Link>
    //                       </div>
    //                     </div>
    //                     <div className="text-center">
    //                       <button
    //                         type="button"
    //                         className="btn bg-white text-primary btn-block"
    //                         onClick={handleLogin}
    //                       >
    //                         Sign Me In
    //                       </button>
    //                     </div>
    //                   </form>
    //                   <div className="new-account mt-3">
    //                     <p className="text-white">
    //                       Don't have an account?
    //                       <Link to="/register" className="text-white">
    //                         Sign up
    //                       </Link>
    //                     </p>
    //                   </div> */}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="bgLockScreen">
      <div className="form-body">
        <div className="row">
          <div className="img-holder">
            <div className="bg" />
            {/* <img
              className=""
              src="./images/NewLogos/MoyoLogo-01-02.png"
              alt=""
            /> */}
            <div className="info-holder"></div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <h1>
                Welcome to <span>Better</span> Healthcare Management
              </h1>
              <div className="form-items">
                <div className="bgCover">
                  {/* <div className="website-logo">
                    <div className="website-logo">
                      <a href="index.html">
                        <div className="logo">
                          <img
                            className="logo-size"
                            src="./images/NewLogos/MoyoLogo-01-02.png"
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div> */}

                  <div className="page-links">
                    <Link to="/login" className="active">
                      Login
                    </Link>
                    <Link to="/register ">Register</Link>
                  </div>

                  {/* <form>
                  <input className="form-control" type="text" name="username" placeholder="E-mail Address" required />
                  <input className="form-control" type="password" name="password" placeholder="Password" required />
                  <div className="form-button">
                    <button id="submit" type="submit" className="ibtn">Login</button> <a href="forget5.html">Forget password?</a>
                  </div>
                </form> */}

                  <Formik
                    initialValues={initialValues}
                    validationSchema={SignInSchema}
                    onSubmit={(values, { resetForm }) => {
                      handleLogin(values, resetForm);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isValid, dirty } = formik;
                      return (
                        <Form className="row">
                          <div className="col-md-12">
                            <Field name="email">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="email"
                                    >
                                      <strong>Email or Phone</strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="email"
                                      placeholder="Enter email or phone.."
                                      autoComplete="email"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.email && touched.email
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-12">
                            <Field name="password">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label
                                      className="mb-1 text-white"
                                      htmlFor="password"
                                    >
                                      <strong>Password</strong>
                                    </label>
                                    <input
                                      type="password"
                                      id="password"
                                      placeholder="Enter password.."
                                      autoComplete="password"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.password && touched.password
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className=" col-md-8 text-center mt-4">
                            <button
                              disabled={!(dirty && isValid)}
                              type="submit"
                              className="btn bg-white text-primary btn-block"
                            >
                              Login
                            </button>
                          </div>

                          <div className=" col-md-12 text-center mt-4">
                            <p className="text-white">
                              <Link
                                to="/forgot-password"
                                className="text-white"
                              >
                                Forgot Password?
                              </Link>
                            </p>
                          </div>

                          {/* <div className="col-md-12 row mt-5">
                          <div className=" col-md-6">
                            <p className="text-white">
                              <Link to="/register" className="text-white">
                                Register
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="text-white">
                              <Link
                                to="/forgot-password"
                                className="text-white"
                              >
                                Forgot Password?
                              </Link>
                            </p>
                          </div>
                        </div> */}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
