// format date
export const trimDate = (date, format = null) => {
  if (date === "0000-00-00" || date === undefined || date === null) {
    return "";
  }
  const myNewDate = new Date(date);
  if (format === "date_only") {
    const finalDate = myNewDate.toISOString().slice(0, 10);
    return finalDate;
  } else if (format === "date_time") {
    const finalDate =
      myNewDate.toISOString().slice(0, 10) +
      " " +
      myNewDate.getHours() +
      ":" +
      myNewDate.getMinutes() +
      ":" +
      myNewDate.getSeconds();
    return finalDate;
  } else {
    return myNewDate;
  }
};

// humanize words
export const humanize = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

// machinize words -- turn to underscore where sopace
export const machinize = (str) => {
  let s = str.trim();
  s = str.toLowerCase();
  return s.replace(/ /g, "_");
};

//time conversion:
export const tConv24 = (time24) => {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = H % 12 || 12;
  h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? " AM" : " PM";
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};
