import React from "react";
import { Table } from "react-bootstrap";

const AppointmentActivity = () => {
  return (
    <div className="card roundediv">
      <div className="card-body">
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Date</th>
              <th>Appointment</th>
              <th>Reason For Visit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ display: "flex" }}>
                <img
                  width={35}
                  className="cirlce"
                  src="./images/avatar/noProfile.jpg"
                  alt=""
                  srcset=""
                />
                <div style={{ paddingRight: "15px" }}></div>
                Gustone
              </td>
              <td>gustone@gmail.com</td>
              <td>2022/02/04</td>
              <td>09:00</td>
              <td>Back Pain</td>
            </tr>
            <tr>
              <td style={{ display: "flex" }}>
                <img
                  width={35}
                  className="cirlce"
                  src="./images/avatar/noProfile.jpg"
                  alt=""
                  srcset=""
                />
                <div style={{ paddingRight: "15px" }}></div>
                Cera
              </td>
              <td>cera@gmail.com</td>
              <td>2022/02/04</td>
              <td>10:00</td>
              <td>Rheumatic Pain</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AppointmentActivity;
