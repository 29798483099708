import React from "react";
import { Link } from "react-router-dom";

const PatientAppointments = (props) => {
    return (
        <tr style={{ textAlign: 'left' }}>

            <td >
                <a to="/profile">
                    {props.docname}
                </a>

            </td>
            <td>{props.date}</td>
            <td>{props.time}</td>
            <td>{props.visitReason}</td>
            <a href="appintment-details" style={{ padding: '0 15px' }}><i style={{ color: '#A0A4A8' }} class="fas fa-pencil-alt"></i></a>
        </tr>
    );
};

export default PatientAppointments;
