import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import MainApp from "./calendar/MainApp";
import AppointmentActivity from "./AppointmentActivity";
import { Table } from "react-bootstrap";
import PatientAppointments from "../components/PatientAppointments.jsx";
import { formatDate } from "@fullcalendar/react";

const Patient = () => {
  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const user = useSelector(selectUser);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsToday, setAppointmentsToday] = useState([]);
  const fullName = user.userName;
  const firstName = fullName.split(" ");
  const fetchAppointmentsUrl =
    user.role === "Patient"
      ? `${process.env.REACT_APP_MAIN_URL}/api/patient/appointments?size=30`
      : `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?doctor_id=${user.user_id}&size=30`;

  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data.items);
        res.data.items.forEach((item) => {
          item.title =
            user.role === "Patient" ? item?.user?.name : item?.patient?.name;
          const dt = new Date(item.date);
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, "") + "T" + item.time_slot
          );
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        });

        // set appointments today
        console.log(res.data.items[0].date);
        const appointmentsT = res?.data?.items.filter(
          (item) =>
            new Date(item.date).toISOString().replace(/T.*$/, "") ===
            new Date().toISOString().replace(/T.*$/, "")
        );
        console.log(appointmentsT);
        setAppointmentsToday(appointmentsT);
        // console.log(res.data);

        res.data?.items?.length > 0 && setAppointments(res.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);
  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="container-fluid">
        <div className="form-head page-titles d-flex mb-md-4">
          <div className="mr-auto">
            <h2 className="text-black font-w600">Doctor</h2>
          </div>
        </div>
      </div> */}
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-lg-8 col-xxl-12">
            <div className="row text-center">
              <div className="col-lg-6 pr-2">
                {/* <img src="images/bg.jpg" alt="" className="bg-img" /> */}
                {/* <div className="noPadBottom"> */}
                <div className="row">
                  <div
                    className="card gradientwelcom fullWidth"
                    style={{ padding: "48px 20px", margin: "0px 16px" }}
                  >
                    <h2>Welcome,{firstName}</h2>
                    {/*{firstName}</h2> */}

                    <p>No pending appointments</p>
                    {/* <h6>0930 - 10th March 2022</h6> */}
                  </div>
                </div>

                <div className="mt-2 row mb-0">
                  <div className="col-lg-12 col-xxl-12">
                    <a href="/book-appointment">
                      <div
                        style={{
                          marginBottom: "2.2rem",
                          backgroundColor: "#2FA5DB",
                        }}
                        className="card  p-4"
                      >
                        <div className="btn-block btn bookBig">
                          <h5>Book Appointment</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div
                    style={{ paddingLeft: "0px", paddingRight: "4px" }}
                    className="col-lg-4"
                  >
                    <div
                      style={{ backgroundColor: "rgba(47, 165, 219, 0.3)" }}
                      className="card noPadBottom"
                    >
                      <div className="someData">
                        <h4 className="">
                          Past
                          <br /> Appointments
                        </h4>
                        <p>1</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingLeft: "0px", paddingRight: "4px" }}
                    className="col-lg-4"
                  >
                    <div
                      style={{ backgroundColor: "rgba(69, 183, 87, 0.3)" }}
                      className="card noPadBottom"
                    >
                      <div className="someData">
                        <h4 className="">
                          Upcoming
                          <br /> Appointments
                        </h4>
                        <p>1</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingLeft: "0px", paddingRight: "4px" }}
                    className="col-lg-4"
                  >
                    <div
                      style={{ backgroundColor: "rgba(102, 35, 130, 0.3)" }}
                      className="card noPadBottom"
                    >
                      <div className="someData">
                        <h4 className="">
                          Last
                          <br /> Diagnosis
                        </h4>
                        <p>None</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: "8px" }} className="row">
                  <div className="card noPadBottom fullWidth">
                    <div className="assignedDoctors">
                      <h4 className="detailHead">Assigned Doctors</h4>
                      {/* <ul>
                        <li>
                          <p className="noPadBottom">
                            Dr. Mike Tukana (Primary){" "}
                          </p>
                        </li>
                        <li>
                          <p className="noPadBottom">Dr. Cecilia Mwanaka </p>
                        </li>
                      </ul> */}
                      <p className="noPadBottom">None</p>
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: "8px" }} className="row">
                  <div
                    className="card fullWidth"
                    style={{ marginBottom: "0.8rem" }}
                  >
                    <div className="currentMedication">
                      <h4 className="detailHead">Current Medication</h4>
                      <ul>
                        <li>
                          <p
                            className="noPadBottom"
                            style={{ marginBottom: "0px" }}
                          >
                            None
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{ marginTop: "-18px" }} className="col-lg-12 pr-0">
                <div className="card">
                  <div className="pr-4 container">
                    <h5 className="apptActivity">Appointment Activity</h5>
                  </div>
                  <Table>
                    <thead>
                      <tr style={{ textAlign: "left" }}>
                        <th>Doctor</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Reason for Visit</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      {appointments.map((appointment) => (
                        <PatientAppointments
                          time={appointment.time_slot}
                          date={formatDate(appointment.date)}
                          docname={appointment.user.name}
                          visitReason={appointment.reason_for_visit}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 pl-2">
            <div className="text-center">
              <div className="card noPadBottom">
                <div className="profileCap">
                  <img
                    src="./images/avatar/noProfile.jpg"
                    style={{ borderRadius: "100px" }}
                    width={138}
                    alt=""
                  />

                  <h5 className="profileName">{user.userName}</h5>
                </div>
              </div>

              <div className="col-lg-12">
                <div
                  style={{ paddingTop: "8px", marginBottom: "0.8rem" }}
                  className="row"
                >
                  <div
                    style={{ paddingLeft: "0px", paddingRight: "4px" }}
                    className="col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Gender</h4>
                        <p style={{ color: "#333" }}>Male</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ paddingRight: "0px", paddingLeft: "4px" }}
                    className=" col-lg-6"
                  >
                    <div className="card">
                      <div className="someData">
                        <h4 className="detailHead">Age</h4>
                        <p style={{ color: "#333" }}>16</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card naModhe">
              <div className="card-body">
                <div className="row sideDetails">
                  <p className="qwerty">
                    <i class="fa-solid fa-phone fa-lg"></i>
                    {user.phone}
                  </p>

                  <p className="qwerty">
                    <i class="fa-solid fa-envelope fa-lg"></i>
                    {user.email}
                  </p>
                  <p className="qwerty">
                    <i class="fa-solid fa-house-user fa-lg"></i>
                    {/* 7 Kaunda Street,
                    Nairobi, Kenya */}
                    Address Not Set
                  </p>
                  {/* <p className="qwerty">
                    <i class="fa-solid fa-gift fa-lg"></i>13 Nov 1998
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <MainApp appointments={appointments} today={appointmentsToday} /> */}
      {/* <AppointmentActivity /> */}
    </div>
  );
};

export default Patient;
