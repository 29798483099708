import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import Admin_ from "../components/Admin";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";

const AdminDashboard = () => {
  const user = useSelector(selectUser);
  const stylebg = {
    background: "#fff",
  };
  return (
    <div id="main-wrapper">
      {/***********************************
            Nav header start
        ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
            Nav header end
        ************************************/}

      {/***********************************
            Header start
        ************************************/}
      <Header />

      {/***********************************
            Header end ti-comment-alt
        ************************************/}
      {/***********************************
            Sidebar start
        ************************************/}
      <LeftBar />
      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}
      {user.isAdmin === true && <Admin_ />}
      {/***********************************
            Content body end
        ************************************/}

      {/***********************************
            Footer start
        ************************************/}
      <Footer />
      {/***********************************
            Footer end
        ************************************/}
    </div>
  );
};

export default AdminDashboard;
