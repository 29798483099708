import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import { Link } from "react-router-dom";

const LeftBar = () => {
  const user = useSelector(selectUser);
  return (
    <div className="deznav">
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          <li>
            <a
              className="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="fas fa-grip-horizontal"></i>
              <span className="nav-text">Dashboard</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <Link to="/dashboard">General</Link>
              </li>
              {user.isAdmin === true && (
                <li>
                  <Link to="/admin-dashboard">Admin</Link>
                </li>
              )}
            </ul>
          </li>
          <>
            {user.role === "DOCTOR" && (
              <>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="fas fa-user-injured"></i>
                    <span className="nav-text">Patient</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <Link to="/patient-manage">Manage</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </>

          {user.isAdmin === true && (
            <>
              <li>
                <a
                  className="has-arrow ai-icon"
                  href="javascript:void()"
                  aria-expanded="false"
                >
                  <i class="fas fa-calendar-check"></i>
                  <span className="nav-text">Appointments</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/book-appointment">Book Appointments</Link>
                  </li>
                  <li>
                    <Link to="/calendar">Appointments Calendar</Link>
                  </li>

                  <li>
                    <Link to="/create-slots">Create A Slot</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow ai-icon"
                  href="javascript:void()"
                  aria-expanded="false"
                >
                  <i class="fas fa-hospital-alt"></i>
                  <span className="nav-text">Rooms</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/rooms">Manage</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow ai-icon"
                  href="javascript:void()"
                  aria-expanded="false"
                >
                  <i class="fas fa-hand-holding-usd"></i>
                  <span className="nav-text">Pricing</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/pricing">Manage</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  className="has-arrow ai-icon"
                  href="javascript:void()"
                  aria-expanded="false"
                >
                  <i class="fas fa-users fa-sm"></i>
                  <span className="nav-text">Users</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/manage-users">Manage</Link>
                  </li>
                </ul>
              </li>
            </>
          )}

          {user.role === "Patient" && (
            <>
              <li>
                <a
                  className="has-arrow ai-icon"
                  href="javascript:void()"
                  aria-expanded="false"
                >
                  <i class="fas fa-calendar-check"></i>
                  <span className="nav-text">Appointments</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/book-appointment">Book Appointments</Link>
                  </li>
                  <li>
                    <Link to="/calendar">Appointments Calendar</Link>
                  </li>
                </ul>
              </li>
            </>
          )}

          <>
            {user.role === "SUPER_ADMIN" && (
              <>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="fas fa-hospital-alt"></i>
                    <span className="nav-text">Health Centers</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/health-centers">Manage</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="fas fa-clipboard-list"></i>
                    <span className="nav-text">Specialities</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/specialities">Manage</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="fas fa-user-injured"></i>
                    <span className="nav-text">Patient</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/patient-manage">Manage</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="fas fa-users"></i>
                    <span className="nav-text">Users</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/manage-users">Manage</a>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </>

          {/* <>
            {user.role === "NURSE" && (
              <>
                {/* <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-user" />
                    <span className="nav-text">Patient</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/patient-manage">Manage</a>
                    </li>
                  </ul>
                </li> 
                <li>
                  <a
                    className="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-calendar" />
                    <span className="nav-text">Appointments</span>
                  </a>
                  <ul aria-expanded="false">
                    <li>
                      <a href="/book-appointment">Book Appointments</a>
                    </li>
                    <li>
                      <a href="/calendar">Appointments Calendar</a>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </> */}
        </ul>
      </div>
    </div>
  );
};

export default LeftBar;
