import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import DataTable from "react-data-table-component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import Preloader from "../components/Preloader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Add_HC_Schema, Add_User_Schema } from "../constants/ValidationSchema";
import Select from "react-select";

const columns = [
  {
    name: "No",
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
  },
  {
    name: "Role",
    selector: (row) => row.role,
  },

  {
    name: "Admin",
    selector: (row) => (
      <span className={row.is_admin === true ? "text-success" : "text-warning"}>
        {row.is_admin ? "Yes" : "No"}
      </span>
    ),
  },
];

const ManageUsers = () => {
  toast.configure();
  const [editShow, setEditShow] = useState(false); //edit modal
  const [addShow, setAddShow] = useState(false); //add modal
  const [dataToEdit, setDataToEdit] = useState({});
  const [loading, setLoading] = useState(false);
  const [healthCenterUsers, setHealthCenterUsers] = useState();
  const [hc, setHc] = useState("");

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;
  const user = useSelector(selectUser);

  const [healthCentersSelect, setHealthCentersSelect] = useState([]);
  const c_url = `${process.env.REACT_APP_MAIN_URL}/api/health-center/`;

  useEffect(() => {
    if (user.role === "SUPER_ADMIN") {
      fetch(c_url, {
        signal: signal,
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + user.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return console.log("health centers fetching did not succeed");
          }

          res.data.items.forEach((item) => {
            item.value = item.id;
            item.label = item.name;
            // now I no longer need them
            delete item.id;
            delete item.name;
            //->will delete the rest later
          });
          // console.log(res.data);

          res.data?.items?.length > 0 && setHealthCentersSelect(res.data.items);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      fetchUsers(user.hc_id);
    }
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="expanded-div py-5 px-5">
        <Button
          variant="success"
          onClick={() => {
            setEditShow(!editShow);
            setDataToEdit(data);
          }}
        >
          <i className="fa fa-pencil"></i> Edit
        </Button>
        <Button
          variant="danger"
          className="ml-5"
          onClick={() => deleteUser(data.id)}
        >
          <i className="fa fa-trash"></i> Delete
        </Button>
      </div>
    );
  };

  //   Initial values for add
  const Add_User_initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    password: "test123",
    role: "",
    is_admin: false,
  };

  // initial values for edit
  //   Initial values for add
  const Edit_User_initialValues = {
    firstName: dataToEdit?.name?.split(" ")[0],
    lastName: dataToEdit?.name?.split(" ")[1],
    email: dataToEdit?.email,
    gender: dataToEdit?.gender,
    // password: "test123",
    role: dataToEdit?.role,
    is_admin: dataToEdit?.is_admin,
    // health_center_id: "",
  };

  const fetchUsers = (health_center) => {
    setLoading(true);
    const fetchUsersUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/health-center/?health_center_id=${health_center}&size=10`;
    fetch(fetchUsersUrl, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.error) {
          return toast.warning("An error occured while fetching center users", {
            position: toast.POSITION.TOP_CENTER,
          });
        }

        toast.success("Users were fetched successfuly", {
          position: toast.POSITION.TOP_CENTER,
        });
        let count = 1;
        res.data.items.forEach((item) => {
          item.count = count;
          count++;
          //->will delete the rest later
        });
        // console.log(res.data);

        res.data?.items?.length > 0 && setHealthCenterUsers(res.data.items);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const addUser = (values, resetForm) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/user/register`;
    const dataToSend = {
      email: values.email,
      firstName: values.firstName,
      gender: values.gender,
      health_center_id: user.role === "SUPER_ADMIN" ? hc : user.hc_id,
      is_admin: values.is_admin,
      lastName: values.lastName,
      password: values.password,
      role: values.role,
    };
    // send request
    fetch(url, {
      signal: signal,
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        // console.log(JSON.stringify(dataToSend));
        resetForm();
        setAddShow(!addShow);
        //adjust state_

        const newUsersData = [...healthCenterUsers];
        dataToSend.id = res?.data?.id;
        dataToSend.name = `${dataToSend.firstName} ${dataToSend.lastName}`;
        newUsersData.unshift(dataToSend);
        let count = 1;
        newUsersData.forEach((item) => {
          item.count = count;
          count++;
        });
        setHealthCenterUsers(newUsersData);

        return toast.info(res.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const editHC = (values) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/user/${dataToEdit?.id}/update`;
    const dataToSend = {
      email: values.email,
      firstName: values.firstName,
      gender: values.gender,
      is_admin: values.is_admin,
      lastName: values.lastName,
      password: values.password,
      role: values.role,
    };
    dataToSend.name = `${dataToSend.firstName} ${dataToSend.lastName}`;
    // send request
    fetch(url, {
      signal: signal,
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setEditShow(!editShow);
        //adjust state_

        // const newCentersData = [...healthCenters];
        // dataToSend.active = "Active";
        // newCentersData.unshift(dataToSend);
        // let count = 1;
        // newCentersData.forEach((item) => {
        //   item.count = count;
        //   //   item.active = item.active === true ? "Active" : "Inactive";
        //   count++;
        // });
        // setHealthCenters(newCentersData);
        dataToSend.id = dataToEdit.id;

        const newUsersData = [...healthCenterUsers];

        const index = healthCenterUsers.findIndex(
          (item) => item.id === dataToEdit.id
        );
        newUsersData.splice(index, 1, dataToSend);
        let count = 1;
        newUsersData.forEach((item) => {
          item.count = count;
          count++;
        });
        setHealthCenterUsers(newUsersData);

        return toast.info(res.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const deleteUser = (id) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/api/user/${id}/delete`;
    if (window.confirm("Are you sure you want to delete this user?")) {
      // send request
      fetch(url, {
        signal: signal,
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            return toast.warning(res.error, {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          //adjust state
          const newUsersData = healthCenterUsers.filter(
            (item) => item.id !== id
          );

          setHealthCenterUsers(newUsersData);

          return toast.info(res.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((err) => {
          console.log(err);
          return toast.warning("An error occured while reaching the server", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  return (
    <div id="main-wrapper">
      {/***********************************
    Header start
************************************/}
      <Header />
      {/***********************************
    Header end ti-comment-alt
************************************/}
      {/***********************************
    Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
    Sidebar end
************************************/}
      {/***********************************
    Content body start
************************************/}
      <div className="content-body">
        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col md={6}>
              <Button
                variant="info"
                className="ml-3"
                onClick={() => setAddShow(!addShow)}
              >
                <i className="fa fa-plus"></i> Add
              </Button>
            </Col>
            {user.role === "SUPER_ADMIN" && (
              <Col md={4}>
                <Select
                  placeholder="Search Health Center..."
                  options={healthCentersSelect}
                  id="health_center_for_users"
                  onChange={(selectedOption) => {
                    fetchUsers(selectedOption.value);
                  }}
                  // defaultInputValue={dataToEdit?.health_center_id}
                />
              </Col>
            )}

            <Col md={12}>
              <DataTable
                columns={columns}
                data={healthCenterUsers}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
              />
            </Col>
          </Row>
        )}
      </div>
      {/***********************************
    Content body end
************************************/}

      {/***********************************
    Footer start
************************************/}
      <Footer />
      {/***********************************
    Footer end
************************************/}

      {/***********************************
    Edit Modal start
************************************/}
      <Modal
        size="lg"
        show={editShow}
        onHide={() => setEditShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {dataToEdit?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Edit_User_initialValues}
            validationSchema={Add_User_Schema}
            onSubmit={(values) => {
              editHC(values);
            }}
            enableReinitialize
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="firstName">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="firstName">
                              <strong>First Name</strong>
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              placeholder="Enter first name.."
                              autoComplete="firstName"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.firstName && touched.firstName
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="firstName"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field name="lastName">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="lastName">
                              <strong>Last Name</strong>
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              placeholder="Enter last name.."
                              autoComplete="lastName"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.lastName && touched.lastName
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="lastName"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="email">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="email">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="Enter email.."
                              autoComplete="email"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.email && touched.email
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="gender">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="gender">
                              <strong>Gender</strong>
                            </label>
                            <select
                              id="gender"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.gender && touched.gender
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="">Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <ErrorMessage
                              name="gender"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-4">
                    <Field name="role">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="role">
                              <strong>Role</strong>
                            </label>
                            <select
                              id="role"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.role && touched.role
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="">Select</option>
                              <option value="DOCTOR">DOCTOR</option>
                              <option value="NURSE">NURSE</option>
                            </select>
                            <ErrorMessage
                              name="role"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-4">
                    <Field name="is_admin">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="is_admin">
                              <strong>Admin</strong>
                            </label>
                            <select
                              id="is_admin"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.is_admin && touched.is_admin
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                            <ErrorMessage
                              name="is_admin"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid)}
                      type="submit"
                      className="btn-block"
                      variant="warning"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Edit Modal end
************************************/}
      {/***********************************
    Add Modal start
************************************/}
      <Modal
        size="lg"
        show={addShow}
        onHide={() => setAddShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add A User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={Add_User_initialValues}
            validationSchema={Add_User_Schema}
            onSubmit={(values, { resetForm }) => {
              addUser(values, resetForm);
              //   console.log(values);
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form className="row">
                  <div className="col-md-6">
                    <Field name="firstName">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="firstName">
                              <strong>First Name</strong>
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              placeholder="Enter first name.."
                              autoComplete="firstName"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.firstName && touched.firstName
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="firstName"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field name="lastName">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="lastName">
                              <strong>Last Name</strong>
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              placeholder="Enter last name.."
                              autoComplete="lastName"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.lastName && touched.lastName
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="lastName"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="email">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="email">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="Enter email.."
                              autoComplete="email"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.email && touched.email
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <Field name="gender">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="gender">
                              <strong>Gender</strong>
                            </label>
                            <select
                              id="gender"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.gender && touched.gender
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="">Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <ErrorMessage
                              name="gender"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  {user.role === "SUPER_ADMIN" && (
                    <div className="col-md-4">
                      <Field name="health_center_id">
                        {(props) => {
                          return (
                            <div className="form-group">
                              <label
                                className="mb-1"
                                htmlFor="health_center_id"
                              >
                                <strong>Health Center</strong>
                              </label>

                              <Select
                                options={healthCentersSelect}
                                id="health_center_id"
                                onChange={(selectedOption) => {
                                  setHc(selectedOption.value);
                                }}
                              />
                              <ErrorMessage
                                name="health_center_id"
                                component="span"
                                className="help-block text-danger"
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  )}

                  <div className="col-md-4">
                    <Field name="role">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="role">
                              <strong>Role</strong>
                            </label>
                            <select
                              id="role"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.role && touched.role
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="">Select</option>
                              <option value="DOCTOR">DOCTOR</option>
                              <option value="NURSE">NURSE</option>
                            </select>
                            <ErrorMessage
                              name="role"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="col-md-4">
                    <Field name="is_admin">
                      {(props) => {
                        return (
                          <div className="form-group">
                            <label className="mb-1" htmlFor="is_admin">
                              <strong>Admin</strong>
                            </label>
                            <select
                              id="is_admin"
                              value={props.field.value}
                              onChange={props.field.onChange}
                              onBlur={props.field.onBlur}
                              className={
                                errors.is_admin && touched.is_admin
                                  ? "form-control input-error text-danger"
                                  : "form-control"
                              }
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                            <ErrorMessage
                              name="is_admin"
                              component="span"
                              className="help-block text-danger"
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className=" col-md-8 text-center mt-4">
                    <Button
                      disabled={!(dirty && isValid) || (!hc && !user.hc_id)}
                      type="submit"
                      className="btn-block"
                      variant="primary"
                    >
                      Add
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/***********************************
    Add Modal end
************************************/}
    </div>
  );
};

export default ManageUsers;
