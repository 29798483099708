import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>Copyright © Developed by Executive Assist LTD</p>
      </div>
    </div>
  );
};

export default Footer;
