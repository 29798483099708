import React, { useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./main.css";
import { Col, Modal, Row, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux_setup/slices/userSlice";

const MainApp = ({ appointments, today }) => {
  let eventGuid = 0;
  const user = useSelector(selectUser);

  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [appointmentToView, setAppointmentToView] = useState({});

  // const [initialEvents, setInitialEvents] = useState([
  //   {
  //     id: createEventId(),
  //     title: "All-day event",
  //     start: todayStr,
  //   },
  //   {
  //     id: createEventId(),
  //     title: "Timed evente",
  //     start: "2022-01-25T16:38",
  //   },
  // ]);

  // appointment
  const requestCancellation = () => {
    if (
      window.confirm(
        "Are you sure you want to request cancellation of this appointment?"
      )
    ) {
      return;
    }
  };

  const rescheduleAppointment = () => {
    if (
      window.confirm(
        "Are you sure you want to request the patient to reschedule this appointment?"
      )
    ) {
      return;
    }
  };

  const [initialEvents, setInitialEvents] = useState(appointments);
  function createEventId() {
    return String(eventGuid++);
  }

  const renderSidebar = () => {
    return (
      <div className="demo-app-sidebar">
        <div className="demo-app-sidebar-section">
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className="demo-app-sidebar-section">
          <label>
            <input
              type="checkbox"
              checked={weekendsVisible}
              onChange={handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className="demo-app-sidebar-section">
          <h2>All Events ({currentEvents.length})</h2>
          <ul>{currentEvents.map(renderSidebarEvent)}</ul>
        </div>
      </div>
    );
  };

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const handleDateSelect = (selectInfo) => {
    // console.log(selectInfo);
    // let title = prompt("Please enter a new title for your event");
    // let calendarApi = selectInfo.view.calendar;
    // calendarApi.unselect(); // clear date selection
    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }
  };

  const cancelAppointment = (e = "") => {
    if (window.confirm(`You are about to cancel this appointment. Continue?`)) {
      console.log("cancel logic");
    }
  };

  const handleEventClick = (clickInfo) => {
    const obj = appointments.filter((event) => event.id === clickInfo.event.id);
    setAppointmentToView(obj[0]);
    setViewAppointment(!viewAppointment);
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {/* {renderSidebar()} */}
          <div className="col-lg-6">
            {/* <div className="card">
             */}
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              views={{
                day: {
                  // name of view
                  titleFormat: {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  },
                  // other view-specific options here
                },
              }}
              initialView="dayGridMonth"
              // editable={true}
              // selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={weekendsVisible}
              events={appointments} // alternatively, use the `events` setting to fetch from a feed
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
            />
            {/* </div>
          </div> */}
          </div>
          <div className="col-lg-6">
            {/* <div className="card">
              <div className="card-body"> */}
            <h4 className="card-intro-title">Todays Appointments</h4>
            <div className>
              <div id="external-events" className="my-3">
                <>
                  {today?.length > 0 ? (
                    <Table>
                      <thead className="bg-gray">
                        <tr>
                          <th>Time</th>
                          <th>Patient</th>
                          <th>Reason</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "14px" }}>
                        {today.map((event, index) => (
                          <tr key={index}>
                            <td>{event.time_slot}</td>
                            <td>
                              <div
                                className="first-time-appt cursor-pointer"
                                onClick={() => {
                                  setAppointmentToView(event);
                                  setViewAppointment(!viewAppointment);
                                }}
                              >
                                {event?.patient?.name}
                              </div>
                            </td>
                            <td>{event.reason_for_visit}</td>
                            <td>
                              {user.role === "DOCTOR" && (
                                <>
                                  <i
                                    style={{ color: "#A0A4A8" }}
                                    class="fas fa-pencil-alt mr-3"
                                    onClick={() => rescheduleAppointment(event)}
                                  ></i>
                                  <i
                                    style={{ color: "#FF6760" }}
                                    className="fa fa-trash cursor-pointer"
                                    onClick={() => requestCancellation(event)}
                                  ></i>
                                </>
                              )}
                              {user.role === "Patient" && (
                                <i
                                  className="fa fa-trash cursor-pointer text-danger"
                                  onClick={() => cancelAppointment(event)}
                                ></i>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No Appointments Today</p>
                  )}
                </>
              </div>
              {/* checkbox */}

              <Link
                className="btn btn-primary btn-event w-100"
                to="book-appointment"
              >
                <span className="align-middle">
                  <i style={{ color: "#fff" }} class="fas fa-plus mr-2"></i>
                </span>{" "}
                Book New Appointment
              </Link>
              {/* </div>
          </div> */}
            </div>
          </div>

          {/* Modal */}
          <Modal
            size="xl"
            show={viewAppointment}
            onHide={() => setViewAppointment(false)}
            // aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                View Appointment
              </Modal.Title>

              <i
                className="fa fa-times"
                style={{ cursor: "pointer" }}
                onClick={() => setViewAppointment(false)}
              ></i>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <strong>Doctor: </strong>
                  {appointmentToView?.user?.name}
                </Col>
                <Col md={6}>
                  <strong>Status: </strong>
                  {appointmentToView?.status}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>Patient: </strong>
                  {appointmentToView?.patient?.name || "Me"}
                </Col>
                <Col md={6}>
                  <strong>Center: </strong>
                  {appointmentToView?.Health_center?.name}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>Date: </strong>
                  {formatDate(appointmentToView?.date)}
                </Col>
                <Col md={6}>
                  <strong>Time: </strong>
                  {appointmentToView?.time_slot}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  <strong>SMS Reminder: </strong>
                  {appointmentToView?.send_reminder === true ? "Yes" : "No"}
                </Col>
                <Col md={6}>
                  <strong>Email Reminder: </strong>
                  {appointmentToView?.email === true ? "Yes" : "No"}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={12}>
                  <strong>Reason For Visit: </strong>
                  {appointmentToView?.reason_for_visit}
                </Col>
              </Row>
              <br />
              <hr />
              {user.role === "Patient" && (
                <Row>
                  <Col sm={6}>
                    <Button variant="danger" onClick={cancelAppointment}>
                      Cancel Appointment
                    </Button>
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Modal>

          {/* modal end */}
        </div>
      </div>
    </div>
  );
};
export default MainApp;

function renderEventContent(eventInfo) {
  return (
    <div className="calendar-event">
      <span className="mr-1">{eventInfo.timeText}</span>

      <span>{eventInfo.event.title}</span>
    </div>
  );
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>{event.title}</i>
    </li>
  );
}
