import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import Admin_ from "../components/Admin";
import Doctor_ from "../components/Doctor";
import Nurse_ from "../components/Nurse";
import Patient_ from "../components/Patient";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";

const Dashboard = () => {
  const user = useSelector(selectUser);
  const stylebg = {
    background: "#fff",
  };
  return (
    <div id="main-wrapper">
      {/***********************************
            Nav header start
        ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
            Nav header end
        ************************************/}

      {/***********************************
            Header start
        ************************************/}
      <Header />

      {/***********************************
            Header end ti-comment-alt
        ************************************/}
      {/***********************************
            Sidebar start
        ************************************/}
      <LeftBar />
      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}
      {/***********************************
            Content body end
        ************************************/}

      {/* If Admin>> Open admin dash */}
      {/* {user.isAdmin === true && <Admin_ />} */}
      <>
        {user.role === "SUPER_ADMIN" && (
          <>
            <Admin_ />
          </>
        )}
      </>
      <>
        {user.role === "DOCTOR" && (
          <>
            <Doctor_ />
          </>
        )}
      </>
      <>
        {user.role === "NURSE" && (
          <>
            <Nurse_ />
          </>
        )}
      </>
      <>
        {user.role === "Patient" && (
          <>
            <Patient_ />
          </>
        )}
      </>

      {/* if (user.role === "SUPER_ADMIN") {
       
      }else if (user.role === "DOCTER"){
        <Doctor_ />
      }
      else if (user.role === "NURSE"){
        <Nurse_ />
      }else if (user.role === "PATIENT"){
        <Patient_ />
      } */}

      {/***********************************
            Footer start
        ************************************/}
      <Footer />
      {/***********************************
            Footer end
        ************************************/}
    </div>
  );
};

export default Dashboard;
