import React from "react";

const ForgotPassword = () => {
  return (




    // <div className="authincation h-100">
    //   <div className="container h-100">
    //     <div className="row justify-content-center h-100 align-items-center">
    //       <div className="col-md-6">
    //         <div className="authincation-content">
    //           <div className="row no-gutters">
    //             <div className="col-xl-12">
    //               <div className="auth-form">
    //                 <div className="text-center mb-3">
    //                   <a href="index.html">
    //                     <img src="images/logo-full.png" alt="" />
    //                   </a>
    //                 </div>
    //                 <h4 className="text-center mb-4 text-white">
    //                   Forgot Password
    //                 </h4>
    //                 <form action="index.html">
    //                   <div className="form-group">
    //                     <label className="text-white">
    //                       <strong>Email</strong>
    //                     </label>
    //                     <input
    //                       type="email"
    //                       className="form-control"
    //                       defaultValue="hello@example.com"
    //                     />
    //                   </div>
    //                   <div className="text-center">
    //                     <button
    //                       type="submit"
    //                       className="btn bg-white text-primary btn-block"
    //                     >
    //                       SUBMIT
    //                     </button>
    //                   </div>
    //                 </form>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>




    <div className="bgLockScreen">
    <div className="form-body">
      <div className="row">
        <div className="img-holder">
          <div className="bg" />
          <div className="info-holder">
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content">
            <div className="form-items">
              <div className="website-logo">
                <a href="index.html">
                  <div className="logo">
                    <img className="logo-size" src="images/logo2.png" alt="" />
                  </div>
                </a>
              </div>
              <h3>Password Reset</h3>
              <p>To reset your password, enter the email address you used to sign in to Moyo</p>
              <form action="index.html">
                <input type="email"
                  className="form-control"
                  name="username" placeholder="E-mail Address" required />
                <div className="form-button full-width">
                  <button id="submit" type="submit" className="ibtn btn-forget">Send Reset Link</button>
                </div>
              </form>
            </div>
            <div className="form-sent">
              <div className="tick-holder">
                <div className="tick-icon" />
              </div>
              <h3>Password link sent</h3>
              <p>Please check your inbox iofrm@iofrmtemplate.io</p>
              <div className="info-holder">
                <span>Unsure if that email address was correct?</span> <a href="#">We can help</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ForgotPassword;
