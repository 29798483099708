import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PatientProfileSchema } from "../constants/ValidationSchema";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
// import "../foreigncss/Profile.css";

const Profile = () => {

  toast.configure();
  const user = useSelector(selectUser);
  const initialValues = {
    email: user?.email,
    firstName: user?.userName?.split(" ")[0],
    lastName: user?.userName?.split(" ")[1],
    phone: user?.phone,
  };

  const abortcontroller = new AbortController();
  const signal = abortcontroller.signal;

  const url = `${process.env.REACT_APP_MAIN_URL}/api/auth/update-profile`;

  const updateProfile = (values, resetForm) => {
    const dataToSend = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    };
    console.log(JSON.stringify(dataToSend));
    // send request
    fetch(url, {
      signal: signal,
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        return toast.info(res.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        return toast.warning("An error occured while reaching the server", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (

    <div id="main-wrapper">
      {/***********************************
        Nav header start
    ************************************/}
      {/* <NavHeader/> */}
      {/***********************************
        Nav header end
    ************************************/}

      {/***********************************
        Header start
    ************************************/}
      <Header />
      {/***********************************
        Header end ti-comment-alt
    ************************************/}
      {/***********************************
        Sidebar start
    ************************************/}
      <LeftBar />
      {/***********************************
        Sidebar end
    ************************************/}
      {/***********************************
        Content body start
    ************************************/}

      <div className="content-body">
        <div className="card">
          <div className="card-body ">
            <div className="row">
              <div className="col-lg-4">
                  <img className="profilePicture" alt="profileImage" src="https://media.istockphoto.com/vectors/default-avatar-photo-placeholder-icon-grey-profile-picture-business-vector-id1327592449?k=20&m=1327592449&s=612x612&w=0&h=6yFQPGaxmMLgoEKibnVSRIEnnBgelAeIAf8FqpLBNww="></img>
              </div>
              <div className="col-lg-8">
                <div className="form-body text-left">
                  <h3 style={{ color: "black" }}>Edit Profile</h3>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={PatientProfileSchema}
                    onSubmit={(values, { resetForm }) => {
                      updateProfile(values, resetForm);
                    }}
                    enableReinitialize
                  >
                    {(formik) => {
                      const { errors, touched, isValid, dirty } = formik;
                      return (
                        <Form className="row">
                          <div className="col-md-5">
                            <Field name="firstName">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label className="mb-1 text-white" htmlFor="firstName">
                                      <strong style={{ color: "black" }}>First Name</strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="firstName"
                                      placeholder="Enter firstName.."
                                      autoComplete="firstName"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.firstName && touched.firstName
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="firstName"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="col-md-6">
                            <Field name="lastName">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label className="mb-1 text-white" htmlFor="lastName">
                                      <strong style={{ color: "black" }}>Last Name</strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="lastName"
                                      placeholder="Enter last Name.."
                                      autoComplete="lastName"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.lastName && touched.lastName
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="lastName"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-5">
                            <Field name="email">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label className="mb-1 text-white" htmlFor="email">
                                      <strong style={{ color: "black" }}>Email</strong>
                                    </label>
                                    <input
                                      type="email"
                                      id="email"
                                      placeholder="Enter email.."
                                      autoComplete="email"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.email && touched.email
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-6">
                            <Field name="phone">
                              {(props) => {
                                return (
                                  <div className="form-group">
                                    <label className="mb-1 text-white" htmlFor="phone">
                                      <strong style={{ color: "black" }}>Phone</strong>
                                    </label>
                                    <input
                                      type="text"
                                      id="phone"
                                      placeholder="Enter phone.."
                                      autoComplete="phone"
                                      value={props.field.value}
                                      onChange={props.field.onChange}
                                      onBlur={props.field.onBlur}
                                      className={
                                        errors.phone && touched.phone
                                          ? "form-control input-error text-danger"
                                          : "form-control"
                                      }
                                    />
                                    <ErrorMessage
                                      name="phone"
                                      component="span"
                                      className="help-block text-danger"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>

                          <div className="col-md-10 mt-5 text-center">
                            <button
                              disabled={!(dirty && isValid)}
                              type="submit"
                              className="btn bg- btn-primary save-bt"
                            >
                              Save Profile
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/***********************************
        Content body end
    ************************************/}

      {/***********************************
        Footer start
    ************************************/}
      <Footer />
      {/***********************************
        Footer end
    ************************************/}
    </div>
  );
};

export default Profile;
