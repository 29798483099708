import * as Yup from "yup";

// Sign In Schema
const SignInSchema = Yup.object().shape({
  email: Yup.string().required("Email or phone is required"),

  password: Yup.string().required("Password is required"),
});

const PatientSignupSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  gender: Yup.string().required("Gender is required"),
  birth_date: Yup.string().required("Birth date is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "Must Contain atleast 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  passwordConfirmation: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const Add_HC_Schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  phone: Yup.string().optional(),

  address: Yup.string().optional(),
});
const Add_User_Schema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email is required"),
  gender: Yup.string().required("Gender is  required"),
  password: Yup.string().optional(),
  role: Yup.string().required("Role is required"),
  is_admin: Yup.string().optional(),
  // health_center_id: Yup.string().required("Health Center is required"),
});

const PatientProfileSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().optional(),
  password: Yup.string()
    .optional()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "Must Contain atleast 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

const AddSpecialitySchema = Yup.object().shape({
  name: Yup.string().required("speciality name is required"),
});

const AddRoomSchema = Yup.object().shape({
  name: Yup.string().required("room name is required"),
});

const EditPriceSchema = Yup.object().shape({
  price: Yup.number().required("price is required"),
});

export {
  SignInSchema,
  PatientSignupSchema,
  Add_HC_Schema,
  Add_User_Schema,
  PatientProfileSchema,
  AddSpecialitySchema,
  AddRoomSchema,
  EditPriceSchema,
};
