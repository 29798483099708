import React from "react";
import { useDispatch } from "react-redux";
import { logout, login } from "../redux_setup/slices/userSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";

const Header = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    if (window.confirm("Are you sure you wanna logout?")) {
      //set everything in local to null
      localStorage.setItem("temp_token", "null");
      return dispatch(logout());
    } else {
      return;
    }
  };
  const BiggerFont = {
    fontsize: "25px;",
  };

  return (
    <>
      <div className="nav-header">
        <a href="/dashboard" className="brand-logo">
          <img className="logo-abbr" src="./images/NewLogos/Favi.png" alt="" />
          <img
            className="logo-compact"
            src="./images/NewLogos/compact.png"
            alt=""
          />
          <img
            className="brand-title"
            src="./images/NewLogos/compact.png"
            alt=""
          />
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </div>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-stuff-left">
              <div className="header-left">
                <div className="dashboard_bar">
                  <div className="input-group search-area d-lg-inline-flex d-none">
                    <i className="fas fa-search fa-xs"></i>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                    />
                  </div>
                </div>
              </div>
              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span className="text-black" style={BiggerFont}>
                        <strong>{user.userName}</strong>
                      </span>
                      <p className="fs-12 mb-0">{user.role}</p>
                    </div>
                    {/* {user.role === "Doctor" && ( */}
                      <img
                        src="./images/avatar/noProfile.jpg"
                        className="circle"
                        width={20}
                        alt=""
                      />
                    {/* )} */}
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a href="/profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ml-2">Profile </span>
                    </a>
                    <a
                      href="./email-inbox.html"
                      className="dropdown-item ai-icon"
                    >
                      <svg
                        id="icon-inbox"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-success"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                        <polyline points="22,6 12,13 2,6" />
                      </svg>
                      <span className="ml-2">Inbox </span>
                    </a>
                    <a
                      onClick={logoutHandler}
                      className="dropdown-item ai-icon"
                    >
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Logout </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
