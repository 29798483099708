import "./App.css";
import Calendar from "./views/Calendar";
import Dashboard from "./views/Dashboard";
import AdminDashboard from "./views/AdminDashboard";
import Profile from "./views/Profile";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "./redux_setup/slices/userSlice";
import Login from "./views/Login";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import { useEffect, useState } from "react";
import Booking from "./views/Booking";
import jwt_decode from "jwt-decode";
import HeathCenters from "./views/HeathCenters";
import Specialities from "./views/Specialities";
import ManageRooms from "./views/ManageRooms";
import ManagePricing from "./views/ManagePricing";
import ManageUsers from "./views/ManageUsers";
import CreateSlots from "./views/CreateSlots";
import PatientManage from "./views/Patientmanage";
import PatientDetails from "./views/PatientDetails";
import Preloader from "./components/Preloader";

function App() {
  const [checking, setChecking] = useState(true);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    const tempToken = localStorage.getItem("temp_token");

    if (tempToken && tempToken !== "null") {
      //chec if it is valid--by decoding the jwt
      const decodedToken = jwt_decode(tempToken);
      console.log(decodedToken);
      var dateNow = new Date();

      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        //has expired
        localStorage.setItem("temp_token", "null");
        dispatch(logout());
        setChecking(false);
      } else {
        dispatch(
          login({
            userName: decodedToken.name,
            role: decodedToken.role || "Patient",
            isAdmin: decodedToken.is_admin || false,
            gender: decodedToken.gender,
            phone: decodedToken.phone,
            user_id: decodedToken.id,
            token: tempToken,
            email: decodedToken.email,
            hc_id: decodedToken.health_center_id,
            birth_date: decodedToken.birth_date,
          })
        );
        setChecking(false);
      }
    } else {
      dispatch(logout());
      setChecking(false);
    }
  }, []);

  return (
    <>
      {checking ? (
        <Preloader />
      ) : (
        <>
          {user ? (
            <Routes>
              <Route path="/*" element={<Dashboard />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="profile" element={<Profile />} />
              <Route path="book-appointment" element={<Booking />} />
              <>
                {user.role === "SUPER_ADMIN" && (
                  <>
                    <Route path="health-centers" element={<HeathCenters />} />
                    <Route path="specialities" element={<Specialities />} />
                    <Route path="patient-manage" element={<PatientManage />} />
                    <Route path="patientdetails" element={<PatientDetails />} />
                  </>
                )}
              </>
              <>
                {user.isAdmin === true && (
                  <>
                    <Route path="rooms" element={<ManageRooms />} />
                    <Route
                      path="admin-dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route path="pricing" element={<ManagePricing />} />
                    <Route path="create-slots" element={<CreateSlots />} />
                  </>
                )}
              </>

              <>
                {(user.role === "SUPER_ADMIN" || user.isAdmin) === true && (
                  <>
                    <Route path="myCalendar" element={<myCalendar_ />} />

                    <Route path="manage-users" element={<ManageUsers />} />
                    <Route path="patient-manage" element={<PatientManage />} />
                  </>
                )}
              </>
              <>
                <Route path="patient-manage" element={<PatientManage />} />
                <Route path="patientprofile" element={<PatientDetails />} />
              </>
              <>
                {(user.role === "NURSE" || user.isAdmin) === true && (
                  <>
                    <Route path="patient-manage" element={<PatientManage />} />
                  </>
                )}
              </>
            </Routes>
          ) : (
            // <Routes>
            //   <Route path="/*" element={<Dashboard />} />
            //   <Route path="calendar" element={<Calendar />} />
            //   <Route path="profile" element={<Profile />} />
            // </Routes>
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Routes>
          )}
        </>
      )}
    </>
  );
}

export default App;
