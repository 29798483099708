import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import DataTable from "react-data-table-component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentActivity from "../components/AppointmentActivity";
import { Table } from "react-bootstrap";
import PatientAppointments from "../components/PatientAppointments.jsx";

const PatientDetails = () => {


    return (

        <div id="main-wrapper">


            {/***********************************
Header start
************************************/}
            <Header />
            {/***********************************
Header end ti-comment-alt
************************************/}
            {/***********************************
Sidebar start
************************************/}
            <LeftBar />
            {/***********************************
Sidebar end
************************************/}
            {/***********************************
Content body start
************************************/}



            <div className="content-body">
                <h5>Find A Patient</h5>

                {/* row */}
                <div className="container-fluid">


                    <div className="row">
                        <div className="col-lg-9 col-xxl-12">
                            <div className="row text-center">
                                <div className="col-lg-6">
                                    {/* <img src="images/bg.jpg" alt="" className="bg-img" /> */}
                                    {/* <div className="noPadBottom"> */}
                                    <div className="">
                                        <div className="card noPadBottom">
                                            <div className="profileCap">
                                                <img src="https://i.ibb.co/D8PMZFx/profile.jpg" style={{ borderRadius: '100px' }} width={138} alt="" />

                                                <h5 className="profileName">Ronald BeatMaker</h5>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-12">
                                        <div style={{ paddingTop: '8px' }} className="row">
                                            <div style={{ paddingLeft: '0px', paddingRight: '4px' }} className="col-lg-6">
                                                <div className="card">
                                                    <div className="someData">
                                                        <h4 className="detailHead">Gender</h4>
                                                        <p>Male</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ paddingRight: '0px', paddingLeft: '4px' }} className=" col-lg-6">
                                                <div className="card">
                                                    <div className="someData">
                                                        <h4 className="detailHead">Age</h4>
                                                        <p>16</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div style={{ paddingLeft: '0px', paddingRight: '4px' }} className="col-lg-4">
                                            <div style={{ backgroundColor: 'rgba(47, 165, 219, 0.3)' }} className="card noPadBottom">
                                                <div className="someData">
                                                    <h4 className="">Past<br /> Appointments</h4>
                                                    <p>21</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft: '0px', paddingRight: '4px' }} className="col-lg-4">
                                            <div style={{ backgroundColor: 'rgba(69, 183, 87, 0.3)' }} className="card noPadBottom">
                                                <div className="someData">
                                                    <h4 className="">Upcoming<br /> Appointments</h4>
                                                    <p>23</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft: '0px', paddingRight: '4px' }} className="col-lg-4">
                                            <div style={{ backgroundColor: 'rgba(102, 35, 130, 0.3)' }} className="card noPadBottom">
                                                <div className="someData">
                                                    <h4 className="">Last<br /> Diagnosis</h4>
                                                    <p>Too Much Style</p>
                                                </div></div>
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: '8px' }} className="row">
                                        <div className="card noPadBottom fullWidth">
                                            <div className="assignedDoctors">
                                                <h4 className="detailHead">Assigned Doctors</h4>
                                                <ul>
                                                    <li>
                                                        <p className="noPadBottom">Dr. Mike Tukana (Primary) </p>
                                                    </li>
                                                    <li>
                                                        <p className="noPadBottom">Dr. Cecilia Mwanaka </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: '8px' }} className="row">
                                        <div className="card fullWidth">
                                            <div className="currentMedication">
                                                <h4 className="detailHead">Current Medication</h4>
                                                <ul>
                                                    <li>
                                                        <p className="noPadBottom" style={{ marginBottom: '0px' }}>Starboy by The Weekend</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-xxl-12 ">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row sideDetails">
                                        <p className=""><i class="fa-solid fa-phone"></i>+12 5123 5512 66</p>
                                        <p className=""><i class="fa-solid fa-envelope"></i>info@mail.com</p>
                                        <p><i class="fa-solid fa-house-user"></i>7 Kaunda Street, Nairobi,  Kenya</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>

            <div className="card roundediv">
                <div className="card-body">

                    <Table>
                        <thead>
                            <tr style={{ textAlign: 'left' }}>
                                <th>Doctor</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Reason for Visit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <PatientAppointments time="09:30" date="08/03/2022" docname="Ronald Dracular" visitReason="Why dyu want to know" />
                            <PatientAppointments time="09:30" date="08/03/2012" docname="React Leaner" visitReason="Better than staying at home" />
                            <PatientAppointments time="09:30" date="08/03/2022" docname="Fizzi Sucks"  visitReason="Better than staying at home" />
                            <PatientAppointments time="09:30" date="08/03/2022" docname="Natasha Slender" visitReason="Better than staying at home" />
                        </tbody>
                    </Table>
                </div>
            </div>
            </div>



            <Footer />
        </div>

    );
};

export default PatientDetails;
