import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftBar from "../components/LeftBar";
import "react-toastify/dist/ReactToastify.css";
import PatientComponent from "../components/PatientComponent";
import { Table } from "react-bootstrap";

const PatientsProfile = () => {
  return (
    <div id="main-wrapper">
      {/***********************************
Header start
************************************/}
      <Header />
      {/***********************************
Header end ti-comment-alt
************************************/}
      {/***********************************
Sidebar start
************************************/}
      <LeftBar />
      {/***********************************
Sidebar end
************************************/}
      {/***********************************
Content body start
************************************/}

      {/***********************************
            Sidebar end
        ************************************/}
      {/***********************************
            Content body start
        ************************************/}

      <div className="content-body">
        <div className="card">
          <div className="topSearch">
            <div className="row">
              <div className="col-lg-4">
                <input
                  placeholder="Name"
                  className="form-control"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div className="col-lg-3">
                <input
                  placeholder="Email"
                  className="form-control"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div className="col-lg-3">
                <input
                  placeholder="+1234567897"
                  className="form-control"
                  type="text"
                  name=""
                  id=""
                />
              </div>
              <div className="col-lg-2">
                <button
                  type="button"
                  class="submitSearch btn btn-primary btn-block"
                >
                  <i class="searchIcon fas fa-search fa-sm"></i>Find Patient
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Table>
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th> </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Reason For Visit</th>
                  <th>Total Appointments</th>
                </tr>
              </thead>
              <tbody>
                <p>In Progress</p>
                {/* <PatientComponent img="https://i.ibb.co/D8PMZFx/profile.jpg" date="08/03/2022" fname="Ronald MakeBeats" email="ronald@gmail.com" visitReason="Better than staying at home" ttlAppts="12" />
                <PatientComponent img="https://i.ibb.co/D8PMZFx/profile.jpg" date="08/03/2012" fname="React Leaner" email="boss@gmail.com" visitReason="Better than staying at home" ttlAppts="12" />
                <PatientComponent img="https://i.ibb.co/D8PMZFx/profile.jpg" date="08/03/2022" fname="Fizzi Sucks" email="fizzi@gmail.com" visitReason="Better than staying at home" ttlAppts="12" />
                <PatientComponent img="https://i.ibb.co/D8PMZFx/profile.jpg" date="08/03/2022" fname="Dell laptop" email="dell@gmail.com" visitReason="Better than staying at home" ttlAppts="12" />
               */}
              </tbody>
            </Table>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default PatientsProfile;

// <>
// {(() => {
//   Add array and Lenth here
//   var thisList = [];
//   for (let i = 0; i < 20; i++) {

//     thisList.push(
//       <div className=" review-table">
//         <div className="media">
//           <img className="mr-3 img-fluid" width={110} src="https://i.ibb.co/D8PMZFx/profile.jpg" alt="DexignZone" />
//           <div className="media-body">
//             <h3 className="fs-20 text-black font-w600 mb-3"><a href="email-read.html" className="text-black">Glee Smiley</a>
//             </h3>
//             <div>
//               <p className="mb-1 fs-14">Email:  <h4 className="text-primary2">email@gmail.com</h4></p>

//             </div>
//             <span className="fs-15">Sunday, 24 July 2020   04:55 PM</span>
//           </div>
//           <div className="media-footer d-flex align-self-center">
//             <div className="disease mr-5">
//               <p className="mb-1 fs-14">Disease</p>
//               <h4 className="text-primary2">Diabetes</h4>
//             </div>
//             <div className="edit ml-auto">
//               <a href="javascript:void(0);" className="btn btn-outline-danger">DELETE</a>
//               {/* Add I Here */}
//               <a href="/Patientdetails" className="btn btn-outline-primary ml-2">Details</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     );

//   }

//   return thisList;
// })()}
// </>
