import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../redux_setup/slices/userSlice";
import { humanize } from "../constants/Essentials";
import PatientComponent from "./PatientComponent";
import DashboardCards from "./DashboardCards";
import { Table } from "react-bootstrap";
import AppointmentActivity from "./AppointmentActivity";
//dummy to be replaced`
var alreadySorted = [
  {
    doctorName: "Gustone Daktari",
    appointments: [
      {
        id: "512c3d14-af5b-4add-b278-d98ef3d5b9eb",
        time_slot: "09:30",

        patient: {
          name: "Lee Tovalds",
        },
      },
      {
        id: "f94226e5-e9e3-46bf-8ac5-812b32fb48f1",
        time_slot: "11:00",

        patient: {
          name: "Sylvan Masha",
        },
      },
      {
        id: "e31458b8-b278-404f-8bf9-b7922f5c0539",
        time_slot: "13:00",

        patient: {
          name: "Sylvan Masha",
        },
      },
      {
        id: "3c5c20c0-1480-49cf-9f3e-849a91df663c",
        time_slot: "11:00",

        patient: {
          name: "Trevor Jones",
        },
      },
      {
        id: "5d0c4f6b-ee39-45fc-8ea3-adbef316dc79",
        time_slot: "10:00",

        patient: {
          name: "Sylvan Masha",
        },
      },
      {
        id: "34da6e70-0a11-4e95-aab2-724d69ced474",
        time_slot: "08:30",

        patient: {
          name: "mucyo chris",
        },
      },
      {
        id: "661902ff-d229-43f8-a85b-b8c376ab9a69",
        time_slot: "09:30",

        patient: {
          name: "mucyo chris",
        },
      },
    ],
  },
  {
    doctorName: "Kuda Gomwe",
    appointments: [
      {
        id: "3b1f0149-d0ea-4430-8029-49f6cbab3500",
        time_slot: "11:30",

        patient: {
          name: "mucyo chris",
        },
      },
      {
        id: "f8b127a3-611d-4f60-87a3-07dbe22e228e",
        time_slot: "09:30",

        patient: {
          name: "Lee Tovalds",
        },
      },
      {
        id: "336d94ae-8322-4a8b-9314-1340823b5966",
        time_slot: "11:30",

        patient: {
          name: "Odin Gomwe",
        },
      },
    ],
  },
  {
    doctorName: "Medina Kalulu",
    appointments: [
      {
        id: "6186db3e-8f3d-470f-9595-7496909dd009",
        time_slot: "10:30",

        patient: {
          name: "Trevor Jones",
        },
      },
    ],
  },
];

const Admin_ = () => {
  const user = useSelector(selectUser);
  const abortcontroller = new AbortController();
  const [dashboardItems, setDashboardItems] = useState([]);
  const signal = abortcontroller.signal;
  // fetch appointments
  const fetchAppointmentsUrl = `${process.env.REACT_APP_MAIN_URL}/api/user/appointments-admin?doctor_id=''&size=30`;

  useEffect(() => {
    fetch(fetchAppointmentsUrl, {
      method: "GET",
      signal: signal,
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data.items);
        res.data.items.forEach((item) => {
          // item.appointment_id = item.id;
          // item.id = count;
          item.title =
            user.role === "Patient" ? item?.user?.name : item?.patient?.name;
          const dt = new Date(item.date);
          // item.value = item.id;
          // item.start = ;
          // const slot = "T"+item.time_slot.substring(0, str.length - 2)
          item.start = String(
            dt.toISOString().replace(/T.*$/, "") + "T" + item.time_slot
          );
          // item.start = "2022-02-01T16:38";

          //->will delete the rest later
        });

        // set appointments today
        const appointmentsT = res?.data?.items.filter(
          (item) =>
            new Date(item.date).toISOString().replace(/T.*$/, "") ===
            new Date().toISOString().replace(/T.*$/, "")
        );
        console.log(appointmentsT);
        //  setAppointmentsToday(appointmentsT);

        //  res.data?.items?.length > 0 && setAppointments(res.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);

  // fetch dashboard items
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAIN_URL}/api/dashboard/dashboard`, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        setDashboardItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return function cleanup() {
      abortcontroller.abort();
    };
  }, []);
  return (
    <div className="content-body">
      {/* row */}

      {/* <div className="form-head d-flex align-items-center mb-sm-4 mb-3">
        <div className="mr-auto">
          <h2 className="text-black font-w600">{humanize(user.role)}</h2>
        </div>
      </div> */}

      <DashboardCards
        bookedAppointments={dashboardItems[0]?.total}
        upcAppointments={dashboardItems[1]?.total}
        activePatients={dashboardItems[2]?.total}
        newPatients={dashboardItems[3]?.total}
      />

      <div className="expert_doctors_area pt-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="doctors_title mb-55">
                <h3>Doctors on Call</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="expert_active owl-carousel">
                {alreadySorted.map(({ doctorName, appointments }, index) => (
                  <div className="single_expert card">
                    <div className="experts_name text-center">
                      <img
                        className="doctorIcon"
                        src="./images/avatar/doctor.jpg"
                        width={50}
                        alt=""
                      />
                      <div className="doctorDetails">
                        <h3>{doctorName}</h3>
                      </div>
                    </div>
                    <div>
                      <div className="text-center md-4">
                        <h5 className="headDate">Thur 10 Mar</h5>
                      </div>

                      <Table className="f14">
                        <thead>
                          <th>Time</th>
                          <th> Patient</th>
                        </thead>
                        <tbody>
                          {appointments.map(({ time_slot, patient }, index) => (
                            <tr>
                              <td>{time_slot}</td>
                              <td>
                                <div className="m-1 apitem bgblue">
                                  {patient.name}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* expert_doctors_area_end */}

      {/* <a href="/ManagePatients">
          <button className="btn btn-light btl-lg shadow"> 
          Manage Patients
          </button> </a> */}

      {/* <div className="card">
        <div className="card-body">
          <Table>
            <thead>
              <tr style={{ textAlign: "left" }}>
                <th> </th>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Reason For Visit</th>
                <th>Total Appointments</th>
              </tr>
            </thead>
            <tbody>
              <PatientComponent
                img="./images/avatar/noProfile.jpg"
                date="08/03/2022"
                fname="Ronald MakeBeats"
                email="ronald@gmail.com"
                visitReason="Better than staying at home"
                ttlAppts="12"
              />
              <PatientComponent
                img="./images/avatar/noProfile.jpg"
                date="08/03/2012"
                fname="React Master"
                email="boss@gmail.com"
                visitReason="Better than staying at home"
                ttlAppts="12"
              />
              <PatientComponent
                img="./images/avatar/noProfile.jpg"
                date="08/03/2022"
                fname="Fizzi Sucks"
                email="fizzi@gmail.com"
                visitReason="Better than staying at home"
                ttlAppts="12"
              />
              <PatientComponent
                img="./images/avatar/noProfile.jpg"
                date="08/03/2022"
                fname="Dell laptop"
                email="dell@gmail.com"
                visitReason="Better than staying at home"
                ttlAppts="12"
              />
            </tbody>
          </Table>
        </div>
      </div> */}
      <AppointmentActivity />
    </div>
  );
};

export default Admin_;
